module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Maytronics Thailand","short_name":"MTSG","start_url":"/","background_color":"#353E47","theme_color":"#58C8DF","display":"standalone","icon":"/opt/build/repo/sea-site-theme/src/images/maytronics_brand.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1054a919784318d94902d0e742cafb4d"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WC6FFP2","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../../sea-site-theme/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
